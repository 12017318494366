var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('HeaderComponent'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-lg-12 col-md-12"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('hr'),_c('div',{on:{"click":function($event){return _vm.mapTouchMake()}}},[_c('iframe',{style:(_vm.computedTouch ? '' : 'pointer-events:none;'),attrs:{"src":"https://yandex.ru/map-widget/v1/?um=constructor%3A248b0f0898f3f50a4d9842c177ab8756158c0c7781e62df316df08a2e399cad3&scroll=false&source=constructor","width":"100%","height":"300","frameborder":"0"}})])]),_c('div',{staticClass:"col-xl-6 col-lg-12 col-md-12"},[_c('img',{attrs:{"src":require('../static/images/home/image_0.png'),"width":"100%"}})])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Пасека располагается в "),_c('b',[_vm._v("д. Горинское")]),_vm._v(" - Тутаевский район Ярославской области. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Совокупность чистой природы, удаленности от трассы и богатого разнообразия трав помогают создавать пчелам "),_c('b',[_vm._v("вкусный, полезный и ароматный мед.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" А "),_c('b',[_vm._v("наша основная задача")]),_vm._v(" - относиться к пчелам с уважением, следить за их здоровьем и делать все возможное, чтобы окружающая пасеку природа развивалась. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Помимо мёда мы так же предлагаем "),_c('b',[_vm._v("прополис, пыльцу, воск, пергу")]),_vm._v(" и другие продукты пчеловодства. "),_c('br'),_vm._v(" ..и не только пчеловодства: "),_c('b',[_vm._v("чаи из сборов местных трав и ягод")]),_vm._v(", а также настойки на "),_c('b',[_vm._v("пчелиной огневке")]),_vm._v(" и "),_c('b',[_vm._v("пчелином подморе")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Пчеловод - "),_c('b',[_vm._v("Алексей Николаевич Тетерин")]),_vm._v(". Связаться можно по телефону "),_c('a',{attrs:{"href":"tel:+79159700770"}},[_vm._v("+7 915 970 0770")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flex-right"},[_c('em',[_vm._v("«Самая чистая радость — "),_c('br'),_vm._v(" радость природы» "),_c('b',[_vm._v("Л.Н. Толстой")])])])
}]

export { render, staticRenderFns }