<template>
    <div class="width-100">
        <div class="col-xl-6 col-lg-6">
            <div class="row" style="margin-top: 16px">
                <div class="col-xs-12 col-sm-12 flex-center">
                    <MainLogo style="height: 50px" />
                </div>
                <div class="col-xs-12 col-sm-12 flex-center">
                    <span class="Honey" :style="getFontSize(32)"
                        >Подсобное хозяйство Тетериных</span
                    >
                </div>
            </div>
            <div class="row" style="margin-top: 8px; gap: 8px">
                <router-link :to="{ name: 'index' }">
                    <button type="button" class="btn btn-primary btn-lg">
                        <YIcon iconName="icon-home" class="white-svg" />
                        <span>Главная</span>
                    </button>
                </router-link>
                <router-link :to="{ name: 'products' }">
                    <button type="button" class="btn btn-primary btn-lg">
                        <YIcon iconName="icon-products" class="white-svg" />
                        <span>Продукты</span>
                    </button>
                </router-link>
            </div>
        </div>
        <hr />
    </div>
</template>

<script>
import MainLogo from "@/main/resources/vue/common/MainLogo";
import YIcon from "@/main/resources/vue/common/YIcon";
import styleGenerator from "@/main/resources/vue/mixins/styleGenerator.js";

export default {
    components: {
        MainLogo,
        YIcon,
    },
    mixins: [styleGenerator],
};
</script>
