<template>
   <div class="card" style="width: 18rem;">
      <img :src="`/images/products/${this.filename}`" class="card-img-top" alt="...">
      <div class="card-body">
         <h5 class="card-title">
            {{ name }}
         </h5>
         <p class="card-text">
            <slot name="description"></slot>
         </p>
         <a class="noselect btn flex-center btn-primary" style="pointer-events: none; margin-bottom: 8px;">
            НАЛИЧИЕ УТОЧНЯЙТЕ ПО ТЕЛЕФОНУ (август-сентябрь)
         </a>
         <a href="tel:+79159700770" class="btn flex-center btn-primary">8-915-970-07-70</a>
      </div>
   </div>
</template>

<script>

export default {
   props: {
      filename: {
         type: String,
         default: 'noproduct.png'
      },
      name: {
         type: String,
         default: 'Продукт'
      }
   },
   methods: {
   },
   mounted() {
   }
}
</script>