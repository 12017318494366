<template>
   <div>

   </div>
</template>

<script>

export default {
   components: {

   },
   mixins: [
   ],
   data() {
      return {
      }
   },
   mounted() {
      this.$router.push("/");
   }
}
</script>

<style></style>