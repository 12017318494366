import Vue from 'vue';
import VueRouter from 'vue-router';
import indexRoutes from '@/main/resources/static/scripts/routes/indexRoutes';
import HomeView from '../../vue/HomeView.vue';
import ProductsView from '../../vue/ProductsView.vue';
import PageNotFound from '../../vue/common/PageNotFound.vue';
Vue.use(VueRouter);
const DEFAULT_TITLE = 'Селективный скрининг';
const baseRoutes = [
    {
        path: '/',
        name: 'index',
        component: HomeView,
        meta: {
            title: 'ЯрПчела - подсобное хозяйство Тетериных >> Главная'
        }
    },
    {
        path: '/products',
        name: 'products',
        component: ProductsView,
        meta: {
            title: 'ЯрПчела - подсобное хозяйство Тетериных >> Продукты'
        }
    },
    {
        path: '*',
        name: 'page-not-found',
        component: PageNotFound,
        meta: {
            title: 'Страница не найдена'
        }
    }
];
const routes = baseRoutes.concat(indexRoutes);
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router;
