<template>
    <div class="page-container">
        <HeaderComponent></HeaderComponent>
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <p>
                    Пасека располагается в <b>д. Горинское</b> - Тутаевский
                    район Ярославской области.
                </p>
                <p>
                    Совокупность чистой природы, удаленности от трассы и
                    богатого разнообразия трав помогают создавать пчелам
                    <b>вкусный, полезный и ароматный мед.</b>
                </p>
                <p>
                    А <b>наша основная задача</b> - относиться к пчелам с
                    уважением, следить за их здоровьем и делать все возможное,
                    чтобы окружающая пасеку природа развивалась.
                </p>
                <p>
                    Помимо мёда мы так же предлагаем
                    <b>прополис, пыльцу, воск, пергу</b> и другие продукты
                    пчеловодства. <br />
                    ..и не только пчеловодства:
                    <b>чаи из сборов местных трав и ягод</b>, а также настойки
                    на <b>пчелиной огневке</b> и <b>пчелином подморе</b>.
                </p>

                <p>
                    Пчеловод - <b>Алексей Николаевич Тетерин</b>. Связаться
                    можно по телефону
                    <a href="tel:+79159700770">+7 915 970 0770</a>
                </p>

                <span class="flex-right"
                    ><em
                        >«Самая чистая радость — <br />
                        радость природы» <b>Л.Н. Толстой</b></em
                    ></span
                >
                <hr />
                <div @click="mapTouchMake()">
                    <iframe
                        :style="computedTouch ? '' : 'pointer-events:none;'"
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A248b0f0898f3f50a4d9842c177ab8756158c0c7781e62df316df08a2e399cad3&amp;scroll=false&amp;source=constructor"
                        width="100%"
                        height="300"
                        frameborder="0"
                    >
                    </iframe>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12">
                <img
                    :src="require('../static/images/home/image_0.png')"
                    width="100%"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderComponent from "@/main/resources/vue/common/HeaderComponent.vue";
import YIcon from "@/main/resources/vue/common/YIcon";

export default {
    name: "HomeView",
    data() {
        return {
            mapTouch: false,
        };
    },
    components: {
        HeaderComponent,
        YIcon,
    },
    computed: {
        computedTouch: function () {
            return this.mapTouch;
        },
    },
    methods: {
        mapTouchMake: function () {
            this.mapTouch = true;
        },
    },
};
</script>
