<template>
    <div class="page-container">
        <HeaderComponent></HeaderComponent>
        <div class="product-row">
            <YProduct filename="honey.jpg" name="Мёд цветочный">
                <template #description>
                    Сезонный мёд разливается по фирменным банкам
                    <b>0,65л, 1л</b> или <b>3л</b>. Другие объемы по просьбе
                    клиента. Звоните для уточнений, вопросов, заказов :)
                </template>
            </YProduct>
        </div>
    </div>
</template>

<script>
import HeaderComponent from "@/main/resources/vue/common/HeaderComponent.vue";
import YProduct from "@/main/resources/vue/common/YProduct";

export default {
    name: "HomeView",
    data() {
        return {
            mapTouch: false,
        };
    },
    components: {
        HeaderComponent,
        YProduct,
    },
    computed: {
        computedTouch: function () {
            return this.mapTouch;
        },
    },
    methods: {
        mapTouchMake: function () {
            this.mapTouch = true;
        },
    },
};
</script>
